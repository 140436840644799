import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {Credential} from '@integration-frontends/workflow-manager/core/model';

export const credentialAdapter = createEntityAdapter<Credential>();

export const credentialSlice = createSlice({
  name: 'credential',
  initialState: credentialAdapter.getInitialState(),
  reducers: {
    credentialsReceived: credentialAdapter.addMany,
    credentialReceived: credentialAdapter.upsertOne,
  },
});
