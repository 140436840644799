import { OrganizationOptions } from '@integration-frontends/common/brandfolder-api';
import { ResourceBase, ResourceType } from '@integration-frontends/workflow-manager/core/model';

export interface Organization extends ResourceBase {
  type: ResourceType.ORGANIZATION;
  name: string;
}

export const ORGANIZATION_REPO_TOKEN = Symbol.for('ORGANIZATION_REPO');

export interface IOrganizationRepo {
  listOrganizations: (options?: OrganizationOptions) => Promise<Organization[]>;
}
