import { filterBy } from '@integration-frontends/common/utils/collection';
import { ResourceBase, ResourceType } from './resource-base';

export interface Section {
  name: string;
  id:  string;
}

export interface SectionData {
  data: Section[];
}

export const filterByBrandfolder = filterBy('brandfolderId');

export const SECTION_REPO_TOKEN = Symbol.for('SECTION_REPO');

export interface ISectionRepo {
  listBrandfolderSections: (brandfolderId: string) => Promise<Section[]>;
  listCollectionSections: (collectionId: string) => Promise<Section[]>;
}
