import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {Client} from '@integration-frontends/workflow-manager/core/model';

export const clientAdapter = createEntityAdapter<Client>();

export const clientSlice = createSlice({
  name: 'client',
  initialState: clientAdapter.getInitialState(),
  reducers: {
    clientsReceived: clientAdapter.addMany,
    clientReceived: clientAdapter.upsertOne,
  },
});
