import { CreateCredentialBody } from '@integration-frontends/common/temporal-api';

import {
  Credential,
  ICredentialRepo,
  credentialsMockData,
} from '@integration-frontends/workflow-manager/core/model';
import { injectable } from 'inversify';

@injectable()
export class CredentialRepoMock implements ICredentialRepo {
  listCredentials(clientId: string): Promise<any[]> {
    return Promise.resolve(credentialsMockData);
  };
  createCredential(clientId: string, credentialBody: CreateCredentialBody): Promise<any> {
    return Promise.resolve(credentialsMockData[0]);
  };
}
