import { DI_CONTAINER } from '@integration-frontends/core';
import { BFStorage, STORAGE_TOKEN } from '../../../../../libs/core/src/lib/storage';
import { PERSISTED_ROUTE_KEY } from './common';
import { Location } from 'history';

export async function getPersistedRoute(): Promise<Location | null> {
  const storage: BFStorage = DI_CONTAINER.get(STORAGE_TOKEN);
  const savedLocationJson = (await storage.getItem(PERSISTED_ROUTE_KEY)) as string;
  return savedLocationJson ? JSON.parse(savedLocationJson) : null;
}
