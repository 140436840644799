import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export interface FormInfo {
  api_host: string,
  brandfolder_key: string,
  collection_key: string,
}

export const formInfoAdapter = createEntityAdapter<FormInfo>();

export const formInfoSlice = createSlice({
  name: 'formInfo',
  initialState: formInfoAdapter.getInitialState(),
  reducers: {
    formInfosReceived: formInfoAdapter.addMany,
  },
});
