import { createWorkflowsEntitiesSelectors, WorkflowsEntitiesStateSelector } from './common/entities-state/selectors';
import { WorkflowsRootState } from './workflows-core-application';

/**
 * Entity selectors
 */

export const workflowsEntitiesStateSelector: WorkflowsEntitiesStateSelector = (
  state: WorkflowsRootState,
) => state.entitiesStateReducer;

export const entitiesSelectors = createWorkflowsEntitiesSelectors(
  workflowsEntitiesStateSelector,
);
const {
  brandfolder,
  client,
  workflow,
  credential,
} = entitiesSelectors;

export const brandfolderEntitySelectors = brandfolder;
export const clientEntitySelectors = client;
export const credentialEntitySelectors = credential;

export const workflowEntitySelectors = workflow;
