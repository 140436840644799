import 'reflect-metadata'; //needs to be first import
import './init'; // needs to be second import

import { RootContainer } from '@integration-frontends/core/ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import 'libs/common/ui/src/lib/styles/tailwind.scss';
import '@brandfolder/react/lib/esm/index.css';
import { BrowserRouter } from 'react-router-dom';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import App from './app/app';
import i18next from './i18n';

ReactDOM.render(
  <StrictMode>
    <RootContainer>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </RootContainer>
  </StrictMode>,
  document.getElementById('root') as HTMLElement,
);
