import { IGetAuthToken } from '@integration-frontends/integration/infrastructure/isomorphic';
import { Store } from '@reduxjs/toolkit';
import { selectIdentity } from '@integration-frontends/common/auth/core/application';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '../../../integration-design-huddle/src/app/common';
import { DI_CONTAINER } from '@integration-frontends/core';

const getApiKeyIdentityStrategy: IGetAuthToken = async () => {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  return (await identityStore.get())?.token;
};

export const createGetApiKeyCustomStrategy: (store: Store<any>) => IGetAuthToken = (store) => {
  return async () => selectIdentity(store.getState())?.token || getApiKeyIdentityStrategy();
};
