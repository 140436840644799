import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {WorkflowListItem} from '@integration-frontends/workflow-manager/core/model';

export const workflowAdapter = createEntityAdapter<WorkflowListItem>();

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState: workflowAdapter.getInitialState(),
  reducers: {
    workflowsReceived: workflowAdapter.upsertMany,
    workflowReceived: workflowAdapter.upsertOne,
  },
});
